import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/tailwind.css';
import { fas } from '@fortawesome/free-solid-svg-icons'
// Import Font Awesome icon component
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// Import Font Awesome core
import { library } from '@fortawesome/fontawesome-svg-core'
// Add icons to the library
library.add(fas)

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const app = createApp(App);

// Register Font SweetAlert component globally
app.use(VueSweetalert2);
// Register Font Awesome component globally
app.component('font-awesome-icon', FontAwesomeIcon)

app.use(router);
app.use(store);

app.mount('#app');
