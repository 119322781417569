<template>
  <div :class="[isDarkMode ? 'dark-bg text-white' : 'bg-gray-100 text-gray-900']" class="landing-page flex items-center justify-center min-h-screen relative">
    <div class="overlay"></div>
    <div :class="[isDarkMode ? 'bg-gray-800' : 'bg-white']" class="p-8 rounded-lg shadow-lg w-full max-w-md mx-4 z-10 relative">
      <h2 :class="[isDarkMode ? 'text-white' : 'text-primary']" class="text-2xl font-semibold mb-6" style="color: #039669">Login</h2>
      
      <!-- Error message -->
      <div v-if="errorMessage" class="text-red-500 text-sm mb-4">
        {{ errorMessage }}
      </div>
      
      <form @submit.prevent="login">
        <div class="mb-4">
          <label :class="[isDarkMode ? 'text-gray-300' : 'text-secondary']" class="block mb-1">Email</label>
          <input 
            v-model="username" 
            type="email" 
            :class="[isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'bg-white border-gray-300 text-gray-900']" 
            class="w-full p-3 border rounded-lg focus:ring focus:border-primary" 
            required 
            @input="validateEmail"
          />
          <span v-if="emailError" class="text-red-500 text-xs">Please enter a valid email.</span>
        </div>
        
        <div class="mb-4">
          <label :class="[isDarkMode ? 'text-gray-300' : 'text-secondary']" class="block mb-1">Password</label>
          <input 
            v-model="password" 
            type="password" 
            :class="[isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'bg-white border-gray-300 text-gray-900']" 
            class="w-full p-3 border rounded-lg focus:ring focus:border-primary" 
            required 
          />
        </div>
        
        <button v-if="loading" class="w-full bg-primary text-white py-2 rounded-lg hover:bg-blue-700 transition duration-300">
          Logging In <font-awesome-icon icon="spinner" spin class="text-2xl"/>
        </button>
        <button v-else type="submit" class="w-full bg-gray-900 text-white py-2 rounded-lg hover:bg-blue-700 transition duration-300">
          Login
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import axios from "@/axios";

export default {
  name: 'LoginPage',
  data() {
    return {
      username: '',
      password: '',
      loading: false,
      errorMessage: '', // Error message for login failure
      emailError: false  // Error state for email validation
    };
  },

  computed: {
    ...mapGetters(['isDarkMode'])
  },
  methods: {
    ...mapActions(['updateStudentUsername']),

    validateEmail() {
      // Simple regex for email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.emailError = !emailRegex.test(this.username);
    },

    async login() {
      if (!this.username || !this.password || this.emailError) {
        this.errorMessage = 'Please enter valid credentials';
        return;
      }
      try {
        this.loading = true;
        const response = await axios.post("auth/login/", { email: this.username, password: this.password });
        this.username = response.data.username;
        this.errorMessage = '';

        if (response.data.is_admin || response.data.is_school_admin) {
          localStorage.setItem("token", response.data.tokens.access);
          localStorage.setItem("refresh", response.data.tokens.refresh);
          localStorage.setItem("user", response.data.username);
          localStorage.setItem("userLoggedIn", "true");
          localStorage.setItem("userIsAdmin", response.data.is_admin || response.data.is_school_admin);
          localStorage.setItem("id", response.data.id);

          this.$store.commit("storeUserDetails", response.data);
          this.loading = false;
          this.$router.push({ name: 'Confirm' });
        }
        if(response.data.is_school_staff){
          this.errorMessage = "This is a staff's account. Login with an admin account to continue.";
        }
        if(response.data.is_parent){
          this.errorMessage = "This is a parent's account. Login with an admin account to continue.";
        }
        this.loading = false;
      } catch (e) {
        console.log(e.response.data);
        this.errorMessage = e.response.data.detail;
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
.landing-page {
  background: url('~@/assets/img/class_room.jpeg') no-repeat center center/cover;
}

.landing-page::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
}
.landing-page > .relative {
  z-index: 10;
}

.bg-primary {
  background-color: #007bff;
}

.text-primary {
  color: #007bff;
}

.text-secondary {
  color: #6c757d;
}

.dark-bg {
  background-color: #1f2937; /* Tailwind dark background */
}
</style>
