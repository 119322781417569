<template>
  <div :class="isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-900'">
    <Modal v-if="showModal" :show="showModal" @username-submitted="handleUsernameSubmitted" />
    <div v-else class="flex items-center justify-center min-h-screen">
      <div :class="isDarkMode ? 'bg-gray-800' : 'bg-white' + ' p-8 rounded-lg shadow-lg w-full max-w-2xl mx-4'">
        <div :class="isDarkMode ? 'bg-gray-700' : 'bg-gray-200' + ' p-4 rounded-lg'">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Modal from '../components/ModalPage.vue';

export default {
  name: 'ExaminationPage',
  components: {
    Modal
  },
  data() {
    return {
      showModal: true,
      slug: ""
    };
  },
  beforeCreate() {
    if (localStorage.getItem('reloaded')) {
      localStorage.removeItem('reloaded');
    } else {
      localStorage.setItem('reloaded', '1');
      location.reload();
    }
  },
  computed: {
    ...mapGetters(['getStudentUsername', 'isDarkMode']),
    studentUsername() {
      return this.getStudentUsername;
    }
  },
  methods: {
    handleUsernameSubmitted(username) {
      this.$store.commit("storeUsername", username);
      this.$store.dispatch('updateStudentUsername', username);
      this.slug = username;
      this.showModal = false;
      this.$router.push({
        name: 'Examination',
        params: { slug: username }
      });
    }
  }
};
</script>

<style scoped>
</style>
