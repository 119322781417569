<template>
  <div v-if="show" :class="isDarkMode ? 'bg-gray-900 bg-opacity-75' : 'bg-gray-800 bg-opacity-75'" class="fixed inset-0 flex items-center justify-center">
    <div :class="isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'" class="p-8 rounded-lg shadow-lg w-full max-w-md mx-4">
      <h2 class="text-2xl font-semibold mb-6 text-primary" style="text-align: center">Learner's CBT ID</h2>
      <form @submit.prevent="submitUsername">
        <div class="mb-4">
          <!-- <label :class="isDarkMode ? 'text-gray-300' : 'text-secondary'" class="block mb-1">ID</label> -->
          <input v-model="username" type="text" :class="isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'bg-white border-gray-300 text-gray-900'" class="w-full p-3 border rounded-lg focus:ring focus:border-primary" required />
        </div>
        <button type="submit" class="w-full bg-primary text-white py-2 rounded-lg hover:bg-blue-700 transition duration-300">Submit</button>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      username: ''
    };
  },
  computed: {
    ...mapGetters(['isDarkMode'])
  },
  methods: {
    submitUsername() {
      if (this.username) {
        this.$emit('username-submitted', this.username);
      }
    }
  }
};
</script>

<style scoped>
</style>
