<template>
  <div class="features min-h-screen flex flex-col items-center justify-center bg-cover bg-center text-white">
    <div class="overlay"></div>
    <div class="content p-8 rounded-lg shadow-lg w-full max-w-2xl text-center">
      <h2 class="text-4xl font-bold mb-8">Features</h2>
      <div class="feature flex items-center mb-4">
        <font-awesome-icon icon="pencil" class="mr-2 text-2xl"/>
        <p class="text-lg">Flexible Exam Creation</p>
      </div>
      <div class="feature flex items-center mb-4">
        <font-awesome-icon icon="shield" class="mr-2 text-2xl"/>
        <p class="text-lg">Secure Testing Environment</p>
      </div>
      <div class="feature flex items-center mb-4">
        <font-awesome-icon icon="check" class="mr-2 text-2xl" />
        <p class="text-lg">Instant Grading</p>
      </div>
      <router-link to="/" class="back-link text-lg mt-8 inline-block bg-blue-500 py-2 px-4 rounded-lg hover:bg-blue-700 transition duration-300">Back to Home</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FeaturesPage',
}
</script>

<style scoped>
.features {
  position: relative;
  background: url('~@/assets/img/class_room.jpeg') no-repeat center center;
  background-size: cover;
  color: #fff;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8); /* Adjust the opacity to make the background darker or lighter */
  z-index: 1;
}

.content {
  position: relative;
  background: rgba(0, 0, 0, 0.7);
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 2;
}

h2 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.feature {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.feature p {
  font-size: 1.25rem;
  margin-left: 0.5rem;
}

.back-link {
  display: inline-block;
  margin-top: 2rem;
  background-color: #007bff;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  text-decoration: none;
  transition: background-color 0.3s;
}

.back-link:hover {
  background-color: #0056b3;
}
</style>
