<template>
  <div :class="`min-h-screen flex flex-col ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-50 text-gray-900'}`" id="app">
    <HeaderComponent @toggleDarkMode="toggleDarkMode" />
    <main class="flex-grow">
      <router-view />
    </main>
    <FooterComponent />
  </div>
</template>

<script>
import HeaderComponent from './components/HeaderComponent.vue';
import FooterComponent from './components/FooterComponent.vue';
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'App',
  components: {
    HeaderComponent,
    FooterComponent
  },
  computed: {
    ...mapState(['isDarkMode'])
  },
  methods: {
    ...mapMutations(['toggleDarkMode']),
  },
  watch: {
    isDarkMode(newVal) {
      if (newVal) {
        document.documentElement.classList.add('dark');
      } else {
        document.documentElement.classList.remove('dark');
      }
    }
  },
  created() {
    // Set the initial state based on the persisted value
    if (this.isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }
};
</script>

<style>
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#app {
  display: flex;
  flex-direction: column;
}

.HeaderComponent {
  position: sticky;
  top: 0;
  z-index: 1000; /* Ensure it's above other content */
}
</style>
