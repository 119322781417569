<template>
  <div class="about min-h-screen flex flex-col items-center justify-center bg-cover bg-center text-white">
    <div class="overlay"></div>
    <div class="content p-8 rounded-lg shadow-lg text-center">
      <h2 class="text-4xl font-bold mb-8">About Us</h2>
      <p class="text-lg mb-4">
        Edufy CBT Center is a subsidiary of <a href="https://edufyschool.com" target="_blank" class="primary-link">Edufy School</a>, dedicated to providing a seamless and secure computer-based testing experience for primary and secondary schools. Our platform offers a range of features tailored to the specific needs of educational institutions, including:
      </p>
      <ul class="list-disc list-inside text-lg mb-4">
        <li>Customizable exams to align with curriculum requirements</li>
        <li>Secure testing environment to maintain integrity</li>
        <li>Comprehensive reporting and analysis tools for educators</li>
      </ul>
      <p class="text-lg mb-8">
        We strive to empower schools with the tools they need to conduct efficient and effective assessments, ultimately enhancing the learning experience for students.
      </p>
      <router-link to="/" class="back-link text-lg mt-8 inline-block bg-blue-500 py-2 px-4 rounded-lg hover:bg-blue-700 transition duration-300">Back to Home</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutPage'
}
</script>

<style scoped>
.about {
  position: relative;
  background: url('~@/assets/img/class_room.jpeg') no-repeat center center;
  background-size: cover;
  color: #fff;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8); /* Adjust the opacity to make the background darker or lighter */
  z-index: 1;
}

.content {
  position: relative;
  background: rgba(0, 0, 0, 0.7);
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 2;
  max-width: 600px;
  text-align: center;
}

.about h2 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.about p, .about li {
  font-size: 1.25rem;
  color: #fff;
  line-height: 1.5;
}

.about ul {
  list-style-type: disc;
  padding-left: 1.5rem;
  margin-bottom: 1.5rem;
}

.primary-link {
  color: #007bff;
  text-decoration: none;
}

.primary-link:hover {
  text-decoration: underline;
}

.back-link {
  display: inline-block;
  margin-top: 2rem;
  background-color: #007bff;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  text-decoration: none;
  transition: background-color 0.3s;
}

.back-link:hover {
  background-color: #0056b3;
}
</style>
