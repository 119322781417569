import { createRouter, createWebHistory } from 'vue-router';
import Login from '../views/LoginPage.vue';
import Examination from '../views/ExaminationPage.vue';
import Confirm from '../views/ConfirmPage.vue';
import LandingPage from '../views/LandingPage.vue'; // Landing page component
import FeaturesComponent from '../views/FeaturesPage.vue'; // Features section component
import AboutComponent from '../views/AboutPage.vue'; // About section component


const routes = [
    {
        path: '/login',
        name: 'Login',
        component: Login,
        beforeEnter: (to, from, next) => {
            // Check if user is logged in and is an admin
            const isLoggedIn = localStorage.getItem('userLoggedIn') === 'true';
            const isAdmin = localStorage.getItem('userIsAdmin') === 'true';

            if (isLoggedIn && isAdmin) {
                // User is authenticated as an admin, allow access to the route
                next('/');
            } else {
                // User is not authenticated as an admin, redirect to login page
                next();
            }
        }
    },
    {
        path: '/confirm',
        name: 'Confirm',
        component: Confirm,
        beforeEnter: (to, from, next) => {
            // Check if user is logged in and is an admin
            const isLoggedIn = localStorage.getItem('userLoggedIn') === 'true';
            const isAdmin = localStorage.getItem('userIsAdmin') === 'true';

            if (isLoggedIn && isAdmin) {
                // User is authenticated as an admin, allow access to the route
                next();
            } else {
                // User is not authenticated as an admin, redirect to login page
                next('/');
            }
        }
    },
    {
        path: '/',
        name: 'LandingPage',
        component: LandingPage
    },
    {
        path: '/features',
        name: 'Features',
        component: FeaturesComponent
    },
    {
        path: '/about',
        name: 'About',
        component: AboutComponent
    },
    {
        path: '/examination/:slug',
        name: 'Examination',
        component: Examination,
        props: true,
        beforeEnter: (to, from, next) => {
            // Check if user is logged in and is an admin
            const isLoggedIn = localStorage.getItem('userLoggedIn') === 'true';
            const isAdmin = localStorage.getItem('userIsAdmin') === 'true';

            if (isLoggedIn && isAdmin) {
                // User is authenticated as an admin, allow access to the route
                next();
            } else {
                // User is not authenticated as an admin, redirect to login page
                next('/');
            }
        }
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
