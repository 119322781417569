import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

const store = createStore({
    state: {
        userDetails: '',
        studentUsername: '',
        isDarkMode: false
    },
    mutations: {
        storeUserDetails(state, username) {
            state.userDetails = username;
        },
        storeUsername(state, username) {
            state.studentUsername = username;
        },
        toggleDarkMode(state) {
            state.isDarkMode = !state.isDarkMode;
        },
        setDarkMode(state, value) {
            state.isDarkMode = value;
        }
    },
    actions: {
        updateStudentUsername({ commit }, username) {
            commit('storeUsername', username); // Fixed to match mutation name
        }
    },
    getters: {
        getStudentUsername(state) {
            return state.studentUsername;
        },
        isDarkMode(state) { // Add the getter here
            return state.isDarkMode;
        }
    },
    plugins: [createPersistedState()]
});

export default store;
