<template>
  <footer :class="`bg-primary text-white p-4 shadow-md sticky bottom-0 z-50 ${isDarkMode ? 'dark:bg-gray-900 dark:text-white' : ''}`">
    <div class="container mx-auto text-center">
      <p>&copy; {{ currentYear }} Edufy CBT Center. Edufy Computer Services.</p>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'FooterComponent',
  data() {
    return {
      currentYear: new Date().getFullYear()
    };
  },
  computed: {
    ...mapGetters(['isDarkMode']),
  }
};
</script>

<style scoped>
</style>
