<template>
  <div class="flex items-center justify-center">
    <button
        type="button"
        @click="previousPage"
        :disabled="currentPage === 1 || disabled"
        class="btn px-3 py-1 rounded border border-gray-300 mr-2"
        style="background-color: chocolate;"
    >
      &lt;
    </button>
    <button
        v-if="currentPage > 3"
        type="button"
        @click="goToPage(1)"
        class="px-3 py-1 rounded border border-gray-300 bg-white text-gray-800"
    >
      1
    </button>
    <span v-if="currentPage > 4" class="text-gray-800 mx-1">...</span>
    <button
        v-for="page in displayedPages"
        type="button"
        :key="page"
        @click="goToPage(page)"
        :class="[
        'px-3 py-1 rounded border border-gray-300',
        currentPage === page ? 'bg-gray-300 text-white bg-emerald-500' : 'bg-white text-gray-800',
      ]"
        :disabled="currentPage === page || disabled"
    >
      {{ page }}
    </button>
    <span v-if="totalPages - currentPage > 3" class="text-gray-800 mx-1">...</span>
    <button
        v-if="totalPages - currentPage >= 3"
        type="button"
        @click="goToPage(totalPages)"
        class="px-3 py-1 rounded border border-gray-300 bg-white text-gray-800"
    >
      {{ totalPages }}
    </button>
    <button
        @click="nextPage"
        type="button"
        :disabled="currentPage === totalPages || disabled"
        class="btn px-3 py-1 rounded border border-gray-300 ml-2"
        style="background-color: chocolate;"
    >
      >
    </button>
  </div>
</template>

<script>
export default {
  props: {
    disabled: { type: Boolean, default: false },
    totalItems: { required: true },
    pageSize: { required: true },
    currentPage: { required: true },
  },
  emits: ['update:currentPage'],
  computed: {
    totalPages() {
      return Math.ceil(this.totalItems / this.pageSize);
    },
    displayedPages() {
      const totalPages = this.totalPages;
      const currentPage = this.currentPage;

      if (totalPages <= 4) {
        return Array.from({ length: totalPages }, (_, i) => i + 1);
      }

      if (currentPage <= 2) {
        return [1, 2, 3, '...'];
      }

      if (currentPage >= totalPages - 1) {
        return ['...', totalPages - 2, totalPages - 1, totalPages];
      }

      return [currentPage - 1, currentPage, currentPage + 1, '...'];
    },
  },
  methods: {
    goToPage(page) {
      if (page === '...') return;

      this.$emit('update:currentPage', page);
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.$emit('update:currentPage', this.currentPage - 1);
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.$emit('update:currentPage', this.currentPage + 1);
      }
    },
  },
};
</script>
