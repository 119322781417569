<template>
  <div class="landing-page">
    <div class="overlay"></div>
    <div class="hero">
      <h1>Welcome to Edufy CBT Center</h1>
      <p>Revolutionizing the way you conduct exams</p>
      <p>An extension of <a href="https://edufyschool.com" target="_blank" >Edufy School Management</a></p>
      <div class="cta-buttons">
        <router-link to="/features" class="cta-button">Features</router-link>
        <router-link to="/about" class="cta-button">About Us</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LandingPage',
  beforeCreate() {
    if (localStorage.getItem('reloaded')) {
      localStorage.removeItem('reloaded');
    } else {
      localStorage.setItem('reloaded', '1');
      location.reload();
    }
  },
}
</script>

<style scoped>
.landing-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: url('~@/assets/img/class_room.jpeg') no-repeat center center/cover;
  position: relative;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6); /* Adjust the opacity to make the background darker or lighter */
  z-index: 1;
}
.landing-page::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Adjust the opacity to make the background darker or lighter */
  z-index: 1;
}

.hero {
  text-align: center;
  z-index: 2; /* Ensure the hero content is above the dark overlay */
  position: relative;
}

.hero h1 {
  font-size: 3rem;
  font-weight: bold;
  color: #fff; /* Change text color to stand out against the dark background */
}

.hero p {
  font-size: 1.5rem;
  color: #ccc; /* Change text color to stand out against the dark background */
  margin-bottom: 2rem;
}

.cta-buttons {
  display: flex;
  justify-content: center;
}

.cta-button {
  display: inline-block;
  background-color: #007bff;
  color: #fff;
  font-size: 1rem;
  padding: 1rem 2rem;
  margin: 0 1rem;
  border-radius: 0.5rem;
  text-decoration: none;
  transition: background-color 0.3s;
}

.cta-button:hover {
  background-color: #0056b3;
}
</style>
